.customFormControl {
    min-width: 120px;
    height: -webkit-fill-available;
    width: 20%;
    background: #FFF;
  }
  .selectInput{
    padding: 8px 0;
  }
  .customTextField {
    flex-grow: 1;
    margin-left: 8px;
    margin-right: 8px;
    height: '-webkit-fill-available';
    background: #FFF;
    .MuiOutlinedInput-root {
    height: 54px;
    .MuiOutlinedInput-input {
      padding: 18px 0; /* Example padding for inner input */
    }
  }
  }
  
  .customInputAdornment {
    padding-right: 0;
  }
  
  .customIconButton {
    margin-right: 0;
    background-color: #ffcc80;
  }
  
  .customIconButton:hover {
    background-color: #ffb74d;
  }
  
  