.cardWrapper {
  margin-top: 20px;
}

.multiSelect{
  height: fit-content;
  align-self: center;
  display: flex;
  justify-content: center;
}

.wrapper {
  flex-wrap: wrap;

  .logoWrapper {
    height: 100%;
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .logo {
      max-width: 150px;
      max-height: 150px;
      width: 100%;
      height: 100%;
    }

    button.removeButton {
      margin-top: 10px;
    }
  }

  

  .card {
    margin: 20px 40px 0 0;

    .button {
      margin-top: 15px;
      margin-left: 8px;
    }
  }

  .switchBlock {
    min-width: 150px;
    display: flex;
    flex-direction: column;
    margin: 20px 20px 0 0;

    .switch {
      margin-right: 0;
      margin-bottom: 0;
    }

    div {
      width: 150px;
      display: flex;
      flex-direction: column;
    }
  }

  .selectBlock {
    width: 250px;
    display: flex;
    flex-direction: column;
    margin: 20px 20px 0 0;
  }

  .input {
    min-width: 250px;
    width: 100%;
    max-width: 300px;
  }

  :global(.material-picker) {
    height: 130px !important;
    width: 130px !important;
  }
}
