.searchWrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;

  .searchFields {
    width: 33%;
  }
  .searchProductField {
    width: 66%;
    align-content: center;
  }
  .searchOverride{
    padding-left: 0 !important;
    margin-left: 0 !important;
  }

  .filterAlign {
    margin-top: 7.5px;
  }
}

.filterOverride{
  margin: 8px 0px !important  ;
}

.addSpace {
  margin: 20px 0;
}

.textInputAlign {
  padding-top: 8px;
  padding-left: 18px !important;
}

.innerWrapper {
  margin-top: 20px;

  > div {
    margin-right: 50px;
  }

  .inputWrapper {
    &:not(:last-child) {
      margin-right: 30px;
    }
  }
}

@media (min-width:500px) and (max-width: 800px) {
  .searchWrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
  
    .searchFields {
      width: 49%;
    }

    .filterAlign {
      margin-top: 0px;
    }
  
  }
}

@media (max-width: 800px) {
  .searchWrapper {
    .searchProductField {
      width: 100%;
    }
  }
}

@media (max-width: 500px) {
  .searchWrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
  
    .searchFields {
      width: 100%;
    }

    .filterAlign {
      margin-top: 0px;
    }
  }
}


