.wrapper {
  margin-top: 40px;
}

.decimal {
  vertical-align: top;
  font-size: 0.85rem;
}

.withoutBorder {
  border: none !important;
}

.verticalHeader {
  position: relative;
  padding: 0 !important;
  width: 40px;
  background-color: rgba(0, 0, 0, 0.14);

  > p {
    position: absolute;
    left: -50px;
    transform: rotate(-90deg);
    white-space: nowrap;
  }
}

.generalInfo {
  background-color: rgba(0, 0, 0, 0.10);
}

.aggregated {
  background-color: rgba(0, 0, 0, 0.14);
}

.borderRight {
  border-right: 1px solid rgba(224, 224, 224, 1);
}

.boldText {
  > * {
    font-weight: bold !important;
  }
}

.totals {
  background-color: rgba(0, 0, 0, 0.04);
}


//Sales And reporting page sticky header styles 

.root {
  overflow-x: auto;
}

.tableContainer {
  max-height: 400px;
  overflow-y: auto;
}

.withoutBorder {
  border: none;
}

.verticalHeader {
  writing-mode: vertical-rl;
}

.stickyRow {
  position: sticky;
  bottom: 0;
  background-color: #fff;
}
